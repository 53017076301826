<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="7">
                    <h4>Deactivate User Request</h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />                      
              </CCol>                      
          </CRow>
        </CCardHeader><br/>
        <CCardBody> 

            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>

          <div class="table-responsive">
          <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">

                <template v-slot:cell(createDate)="{ item }">                    
                    {{ item.createDate | dateWithTime }}                    
                </template>  

          <template v-slot:cell(actions)="{ item }">            
            <span v-if='item.recordStatus=== "ACTIVE"'>  
            <b-dropdown variant="info" text="Actions" size="md"> 
                <router-link to="#" exact v-on:click.native="completeAction(item.id, item.mobileNumber)" tag="b-dropdown-item" > Deactivated Account</router-link>
                <router-link to="#" exact v-on:click.native="cancelAction(item.id)" tag="b-dropdown-item"> Cancel Request</router-link> 
            </b-dropdown> 
            </span>  
            <span v-else>
            <button :disabled="!btnAction" variant="info">Action</button>  
            </span>        
          </template>

        </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>

          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import AuthService from '@/api/AuthService.js';
import moment from 'moment';

 export default {
    name: "deactivate",
    data() { var date = new Date();
      return {   
        btnAction:false,     
        items:[],             
        fields: [  
          {
            key: 'createDate',
            label : 'Created Date',
            sortable: true
          },
          {
            key: 'userIdentify',
            label : 'User ID',
            sortable: true
          },
          {
            name : 'userName',
            key: 'userName',
            label : 'User Name',
            sortable: true
          },    
          {
            key: 'mobileNumber',
            label : 'Phone Number',
            sortable: true
          },
          {
            key: 'reason',
            label : 'Reason',
            sortable: true
          },          
          {
            key: 'recordStatus',
            label : 'Request Status',
            sortable: true
          },
          {            
            key: 'actions',
            label: 'Actions'         
          }                       
                    
        ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
        seen:false,
        msg :'',
        color:'' 
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        passOnlyString(value){
           if(!value) return ''
           value = value.toString()
           return value.replace(/[^a-zA-Z ]/g, "")
        },       

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },

        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        }


    },

    created () {  
      //this.isSuperAdmin(); 
      this.getUserDeactReqList();
    },
    methods: { 

      isSuperAdmin(){
        if(localStorage.getItem('sa')==="false"){  
            this.$router.push('/dashboard');
        }
      },

      completeAction(id,userName){
         if(confirm('Are you sure you want complete request ? ')) { 
            this.DeactivateOpayUser(id,userName);
         }
      },

      cancelAction(id){
         if(confirm('Are you sure you want cancel request ? ')) { 
            this.cancelDeactivateUser(id);
         }
      },
      
      
      DeactivateOpayUser: function(id,userName) {
        AuthService.DeactivateOpayUser(id).then(resp => {  //console.log(resp);
            if(resp.message==="Success"){ 
              
              AuthService.DeactivateAuthUser(userName).then((resp2) => {   //console.log(resp2); 
                  if(resp2.message=="SUCCESS"){
                     alert("Deactivate User Complete!");      
                  }   
              }).catch((e) => { console.log(e); }); 

              this.getUserDeactReqList();
            } else {
              this.msg   =  !resp.data.message ? "Deactivate User Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;              
            } 
            setTimeout( () =>  this.seen=false , 5000);            
        }, error => {
          this.loading = false;
        });
      },

      cancelDeactivateUser: function(id) {
        AuthService.cancelDeactivateUser(id).then(resp => {  //console.log(resp);
            if(resp.message==="Success"){ 
              alert("Deactivate User Cancelled!");  
              this.getUserDeactReqList();
            } else {
              this.msg   =  !resp.data.message ? "Cancelled Deactivate User Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;              
            } 
            setTimeout( () =>  this.seen=false , 5000);            
        }, error => {
          this.loading = false;
        });
      },

      getUserDeactReqList: function() {
        AuthService.getUserDeactReqList().then(resp => {  //console.log(resp);
           this.items = resp; 
        }, error => {
          this.loading = false;
        });
      },  
    }    
 }; 

</script>
<style>
    .table{ hoverflow-y:scroll; }
</style>